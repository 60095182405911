import React from "react"
import { Image } from "@chakra-ui/image"
import { Text } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import "./OurValues.css"
import { ourValuesData } from "../../../config/About/OurValuesData"

const OurValues = () => {
  return (
    <Box align="center" mb="20px" p="15px">
      <Text
        fontWeight="700"
        color="var(--secondary-headingColor)"
        fontSize="var(--secondary-heading)"
      >
        Our Values
      </Text>
      <Text fontSize="18px" color="#858B91" mt="10px" mb="60px">
        Values are highlighted here
      </Text>

      <Box className="ourValueItem__container">
        {ourValuesData.map(item => (
          <OurValueItem key={item.id} item={item} />
        ))}
      </Box>
    </Box>
  )
}

export default OurValues

const OurValueItem = ({ item }) => {
  return (
    <Box mt="15px">
      <Box className="ourValueItem">
        <Image className="ourValueItem__img" src={item.image} />
        <Box mt="20px" className="serviceItem__content">
          <Text
            color="#0f2137"
            fontSize="18px"
            fontWeight="700"
            textAlign="center"
            mb="12px"
          >
            {item.title}
          </Text>
          <ul>
            {item.points.map(el => (
              <li>{el.data}</li>
            ))}
          </ul>
        </Box>
      </Box>
    </Box>
  )
}
