import React from "react"
import { Box, Text } from "@chakra-ui/layout"

const About = () => {
  return (
    <Box className="about__banner">
      <Box maxW="768px" margin="0 auto" mb="60px" fontSize="24px" p="15px">
        <Text textAlign="center" fontSize="40px" fontWeight="700" mb="50px">
          About Us
        </Text>
        <Box className="about__leftBorder">
          <Text mb="20px" pl="20px">
            <strong>Sensetech Innovations</strong> aims to bring about cost
            effective digital solutions that enables businesses to better engage
            their customers and grow their businesses in this digital era.
          </Text>
          <Text pl="20px">
            We believe that businesses should continue to focus on what they do
            best to serve their customers and not get bogged down with the
            details of digital transformation that is necessary to bring about
            better competitive edge. Through a collaborative relationship with
            the businesses, we offer consultancy, details out the necessities of
            the digital transformation project, manages and delivers impeccably
            executed solution that will ultimately lead to better customer
            satisfaction of the businesses.
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default About
