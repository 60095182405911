import authenticity from "../../images/about/authentication.png"
import centre from "../../images/about/centre.png"
import focus from "../../images/about/focus.png"
import key from "../../images/about/key.png"
import performance from "../../images/about/performance.png"

export const ourValuesData = [
  {
    id: 1,
    title: "Authenticity",
    points: [
      { id: 1, data: "We mean what we say and do" },
      { id: 2, data: "We own our mistakes and weaknesses" },
    ],
    image: authenticity,
  },
  {
    id: 2,
    title: "People Centricity",
    points: [
      { id: 1, data: "We value collaboration and teamwork" },
      {
        id: 2,
        data: "Happiness fulfillment, and growth of people matter to me",
      },
    ],
    image: centre,
  },
  {
    id: 3,
    title: "Customer Focus",
    points: [
      { id: 1, data: "We work with a nimble mindset" },
      { id: 2, data: "We do whatever it takes to meet the customer needs" },
    ],
    image: focus,
  },
  {
    id: 4,
    title: "Complete Ownership",
    points: [
      { id: 1, data: "The buck stops with me" },
      { id: 2, data: "I focus on solution, not the problem" },
    ],
    image: key,
  },
  {
    id: 5,
    title: "Continuous Development",
    points: [
      { id: 1, data: "We invest in learning new skills" },
      {
        id: 2,
        data: "We frequently think ways to improve efficiency and productivity",
      },
    ],
    image: performance,
  },
]
