import { Box } from "@chakra-ui/layout"
import { Helmet } from "react-helmet"
import React from "react"
import About from "../components/About/About/About"
import OurValues from "../components/About/OurValues/OurValues"
import FooterCommon from "../components/Utilities/FooterCommon/FooterCommon"
import Navbar from "../components/Utilities/Navbar/Navbar"
import "../styles/about.css"
import logo from "../images/sensetechLogo.png"

const about = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>SenseTech: About</title>
        <meta
          name="description"
          content="SenseTech : App Development || Cloud Support || Migration to Micro Frontend || Cloud Migration support || RPA || Chatbot || E-commerce store"
        />
        <meta
          name="keywords"
          content="mobile app development , web app development , software company , IT solution, IT services"
        />
        <meta name="author" content="SenseTech Innovations Private Limited" />
        <meta property="og:image" content={logo} />
        <link rel="canonical" href="https://thesensetech.com" />
      </Helmet>
      <Box className="about" pt="150px">
        <Navbar />
        <About />
        <OurValues />
        <FooterCommon />
      </Box>
    </>
  )
}

export default about
